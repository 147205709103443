import React, { useState } from 'react';
import AnatomyThreeScene from './AnatomyThree';

const AnatomyInviteCase = () => {
  const [RoomNumber, setRoomNumber] = useState('');
  const [userId, setUserId] = useState('');
  const [startSession, setStartSession] = useState(false);
  const test = 3

  const handleJoinRoom = (e) => {
    e.preventDefault();
    if (RoomNumber.trim()) {
      setStartSession(true);
    } else {
      alert('Please enter both Room Number and User ID.');
    }
  };
const userInfo = {id:null}
userInfo.id =1
  return (
    <div>
      {!startSession ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h2>Join Anatomy Session</h2>
          <form onSubmit={handleJoinRoom}>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="RoomNumber">Room Number:</label>
              <input
                id="RoomNumber"
                type="text"
                value={RoomNumber}
                onChange={(e) => setRoomNumber(e.target.value)}
                placeholder="Enter Room Number"
                style={{ marginLeft: '10px', padding: '5px' }}
              />
            </div>
           
            <button
              type="submit"
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                cursor: 'pointer',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
              }}
            >
              Join Room
            </button>
          </form>
        </div>
      ) : (
        <AnatomyThreeScene RoomNumber={RoomNumber} userId={test} />
      )}
    </div>
  );
};

export default AnatomyInviteCase;
