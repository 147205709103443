import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AnatomyThreeScene from './AnatomyThree';
import AnatomyInviteLink from './AnatomyInviteLink';

const AnatomyRegularCase = () => {
  const userInfo = useSelector((state) => state.userLogin.userInfo); // Fetch user info from Redux
  const [startExploration, setStartExploration] = useState(false); // Tracks whether to start anatomy exploration

  // Derive room number and user ID
  const roomNumber = 133 * 35;
 userInfo.id = 1;

  return (
    <div>
      {!startExploration ? (
        <div>
          <center>
          <h2>Welcome to Anatomy Explorer!</h2>
         </center>
          <p>Join a room to study anatomical structures! Your friends can join the same room to study the structures with you:</p>
          {/* Show invite link */}
          <AnatomyInviteLink roomNumber={roomNumber} userId={userInfo.id || 2} />
          <br/>
          <br/>
        
        </div>
      ) : (
        // Show the AnatomyThreeScene once exploration starts
        <AnatomyThreeScene roomNumber={roomNumber} userId={userInfo.id} />
      )}
      <br/>
      <br/>
    </div>
  );
};

export default AnatomyRegularCase;
