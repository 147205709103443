import React, { useRef, useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import LanguageSelector from "./LanguageSelector";
import Output from "./Output";
import './CodeEditor.css';

const CodeEditor = () => {
  const editorRef = useRef();
  const [value, setValue] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [languages, setLanguages] = useState([]);

  const onMount = (editor) => {
    editorRef.current = editor;
    editor.focus();
  };

  const onSelect = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    setValue(""); // Clear editor when switching languages
  };

  useEffect(() => {
    async function fetchRuntimes() {
      try {
        const response = await fetch("https://emkc.org/api/v2/piston/runtimes");
        const data = await response.json();
        const formattedLanguages = data.map(({ language, version }) => [
          language,
          version,
        ]);
        setLanguages(formattedLanguages);
      } catch (error) {
        console.error("Failed to fetch languages:", error);
      }
    }
    fetchRuntimes();
  }, []);

  return (
    <div>
      {/* Instructional Section */}
      <div className="instructional-container">
        <h2 className="instructional-title"> Code Editor</h2>
        <p className="instructional-text">
          Use this editor to practice your <strong>programming skills</strong> without needing to install an Integrated Development Environment (IDE). 
          Write and run code directly in your browser across multiple programming languages.
        </p>
        <ul className="instructional-list">
          <li>Select a language from the dropdown menu.</li>
          <li>Write your code in the editor below.</li>
          <li>Click "Run" to execute your code and view the output.</li>
        </ul>
        <p className="instructional-note">
          This editor supports multiple languages like <strong>Python</strong>, <strong>JavaScript</strong>, and <strong>C++</strong>. Happy coding!
        </p>
      </div>
    <div className="code-editor-layout">
      


      {/* Code Editor and Output Section */}
      <div className="editor-output-container">
        <div className="editor-container">
          <LanguageSelector
            language={language}
            onSelect={onSelect}
            languages={languages}
          />
          <Editor
            height="75vh"
            theme="vs-dark"
            language={language}
            value={value}
            onMount={onMount}
            onChange={(newValue) => setValue(newValue)}
            options={{
              minimap: { enabled: false },
            }}
          />
        </div>
        <Output editorRef={editorRef} language={language} />
      </div>
    </div>
    </div>
  );
};

export default CodeEditor;
