import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { PMREMGenerator } from 'three';
import './AnatomyThreeScene.css';
import { useSelector } from 'react-redux';
import AnatomyInviteLink from './AnatomyInviteLink';

const AnatomyThreeScene = ({ RoomNumber = 5, userId = 0 }) => {
  const { roomNumber: paramRoomNumber, userId: paramUserId } = useParams(); // Get route params
  const mountRef = useRef(null);
  const [hoveredPart, setHoveredPart] = useState('');
  const [currentModel, setCurrentModel] = useState('CNS2.glb');
  const [scale, setScale] = useState(0.05);
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [host, setHost] = useState(false)
  const controlsRef = useRef(null);

  // Get userInfo from Redux
  const userInfo = useSelector((state) => state.userLogin.userInfo); 

  // Determine roomNumber and userId based on params or fallback to Redux/state logic
  const roomNumber = paramRoomNumber || RoomNumber; // Use params or a default

  function generateRandomNumber() {
    return Math.floor(Math.random() * 1000000); // Generates a random number between 0 and 999,999
  }

  useEffect(() => {
    let currentScene = null; // To hold the current loaded scene
    let frameId; // To track the animation frame for cleanup

    // Set up the main Three.js scene
    const scene = new THREE.Scene(); // Create a new scene
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 2000);
    camera.position.set(0, 0.11, 0.1); // Adjust camera for better initial view
    scene.background = new THREE.Color("#191919"); // Background color for the scene

    const renderer = new THREE.WebGLRenderer({ antialias: true }); // Create the renderer
    renderer.setSize(window.innerWidth, window.innerHeight); // Match renderer size to viewport
    renderer.toneMapping = THREE.ACESFilmicToneMapping; // Enable tone mapping
    renderer.toneMappingExposure = 0.5; // Control the brightness
    renderer.shadowMap.enabled = true; // Enable shadows
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Set shadow type for softness
    mountRef.current.innerHTML = ''; // Clear previous scene from the DOM
    mountRef.current.appendChild(renderer.domElement); // Attach the renderer canvas to the DOM

    const controls = new OrbitControls(camera, renderer.domElement); // Add camera controls
    controls.enableDamping = true; // Enable damping for smooth camera motion
    controls.dampingFactor = 0.05; // Adjust damping factor
    controlsRef.current = controls; // Store controls in a ref for later use

    // Add a listener for camera changes to sync via WebSocket
    controls.addEventListener('change', () => {
      if (socket) {
        const cameraState = {
          position: camera.position.toArray(),
          rotation: camera.rotation.toArray(),
        };
        socket.send(JSON.stringify({ type: 'camera_update', cameraState })); // Broadcast camera state
      }
    });

    // Set up the room environment for realistic lighting
    const pmremGenerator = new PMREMGenerator(renderer); // Generate pre-filtered radiance map
    const environment = new RoomEnvironment(pmremGenerator); // Room-like environment
    scene.environment = pmremGenerator.fromScene(environment).texture; // Set as the scene environment

    // Helper function to add lighting based on object size and position
    const addLightsBasedOnObject = (scene, object) => {
      const box = new THREE.Box3().setFromObject(object); // Calculate bounding box of the object
      const objectCenter = box.getCenter(new THREE.Vector3()); // Get the center of the bounding box
      const objectSize = box.getSize(new THREE.Vector3()); // Get the size of the bounding box
      const lightIntensity = 3; // Intensity of the directional lights
      const lightDistance = objectSize.length() * 1.5; // Adjust light distance based on object size

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // Add ambient light
      scene.add(ambientLight);

      // Add directional lights from multiple angles
      const directionalLight1 = new THREE.DirectionalLight(0xffffff, lightIntensity);
      directionalLight1.position.set(objectCenter.x + lightDistance, objectCenter.y + lightDistance, objectCenter.z);
      directionalLight1.castShadow = true; // Enable shadows
      directionalLight1.shadow.mapSize.width = 2048; // Shadow map resolution
      directionalLight1.shadow.mapSize.height = 2048;
      scene.add(directionalLight1);

      const directionalLight2 = new THREE.DirectionalLight(0xffffff, lightIntensity);
      directionalLight2.position.set(objectCenter.x - lightDistance, objectCenter.y + lightDistance, objectCenter.z);
      directionalLight2.castShadow = true;
      scene.add(directionalLight2);

      const directionalLight3 = new THREE.DirectionalLight(0xffffff, lightIntensity);
      directionalLight3.position.set(objectCenter.x, objectCenter.y + lightDistance, objectCenter.z + lightDistance);
      directionalLight3.castShadow = true;
      scene.add(directionalLight3);
    };

    // Set up the GLTF model loader
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('three/examples/jsm/libs/draco/gltf'); // Path to DRACO decoder
    loader.setDRACOLoader(dracoLoader); // Attach DRACO to GLTF loader

    // Load a GLTF model into the scene
    const loadModel = (modelPath) => {
      if (currentScene) {
        scene.remove(currentScene); // Remove the previous model from the scene
      }

      setLoading(true); // Show loading spinner
      loader.load(
        modelPath,
        (gltf) => {
          // Once the model is loaded, traverse its children
          gltf.scene.traverse((node) => {
            if (node.isMesh) {
              node.castShadow = true; // Enable casting shadows
              node.receiveShadow = true; // Enable receiving shadows
            }
          });

          currentScene = gltf.scene; // Store the loaded model in currentScene
          currentScene.position.set(0, 0, 0); // Position the model at the origin
          currentScene.scale.set(scale, scale, scale); // Scale the model
          scene.add(currentScene); // Add the model to the scene
          setLoading(false); // Hide loading spinner
        },
        (xhr) => {
          // Log loading progress
          console.log(`Model loading progress: ${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        (error) => {
          console.error(`Error loading model: ${modelPath}`, error); // Log errors
          setLoading(false); // Hide loading spinner
        }
      );
    };

    loadModel(currentModel); // Load the initial model

    // Raycaster for detecting hovered parts
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    // Handle mouse movement for hover detection
    const handleMouseMove = (event) => {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(scene.children, true);
      if (intersects.length > 0) {
        setHoveredPart(intersects[0].object.name || 'Unknown part');
      } else {
        setHoveredPart('');
      }
    };

    // Handle window resize for responsive rendering
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('mousemove', handleMouseMove); // Add mouse movement listener
    window.addEventListener('resize', handleResize); // Add window resize listener

    // Axes helper for scene reference
    const axesScene = new THREE.Scene();
    const axesHelper = new THREE.AxesHelper(0.1);
    axesScene.add(axesHelper);

    const axesCamera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 0.01, 10);
    axesCamera.position.set(1, 1, 1);
    axesCamera.lookAt(0, 0, 0);

    const axesRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    axesRenderer.setSize(100, 100);
    axesRenderer.domElement.style.position = 'absolute';
    axesRenderer.domElement.style.top = '10px';
    axesRenderer.domElement.style.left = '10px';
    axesRenderer.setClearColor(0x000000, 0);
    mountRef.current.appendChild(axesRenderer.domElement);

    const animate = () => {
      controls.update();
      renderer.render(scene, camera);
      axesRenderer.render(axesScene, axesCamera);
      frameId = requestAnimationFrame(animate); // Request next frame
    };
    animate(); // Start the animation loop

    // Cleanup when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(frameId); // Stop animation loop
      mountRef.current.removeChild(renderer.domElement); // Remove renderer DOM element
    };
  }, [currentModel, scale, socket]);

  useEffect(() => {
    if (socket && controlsRef.current) {
      const controls = controlsRef.current;
  
      // Update and send camera position/rotation on control change
      controls.addEventListener('change', () => {
        const cameraPosition = {
          x: controls.object.position.x,
          y: controls.object.position.y,
          z: controls.object.position.z,
        };
  
        const cameraRotation = {
          x: controls.object.rotation.x,
          y: controls.object.rotation.y,
          z: controls.object.rotation.z,
        };
  
        socket.send(
          JSON.stringify({
            type: 'camera_update',
            position: cameraPosition,
            rotation: cameraRotation,
          })
        );
      });
    }
  }, [socket]);
  

  useEffect(() => {
    const ws = new WebSocket(`wss://api.andrewslearning.com/wschat/screen-sharing/${roomNumber}/${userId}/`);
    setSocket(ws);
  
    ws.onopen = () => {
      console.log("WebSocket connected:", { roomNumber, userId });
  
      // Identify as host and send the initial message
      ws.send(
        JSON.stringify({
          type: "identify_host",
          profile_number: userId || generateRandomNumber(), // Use user ID or generate random number
          sender: userInfo.username || "none", // Use username or fallback to "none"
        })
      );
    };
  
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
    
      if (data.type === 'host_acknowledged' && data.host_id === userInfo.id) {
        setHost(true);
        console.log("Host acknowledged:", data.host_id);
      }
    
      if (data.type === 'camera_update') {
        const { position, rotation } = data.cameraState;
        if (controlsRef.current) {
          const camera = controlsRef.current.object;
      
          // Correct for swapped axes in position
          camera.position.set(position[0], position[1], position[2]);
      
          // Correct for swapped axes in rotation
          camera.rotation.set(rotation[2], rotation[1], rotation[0]);
      
          controlsRef.current.update(); // Sync controls
          console.log('Camera updated:', { position, rotation });
        }
      } else if (data.type === 'model_selected') {
        setCurrentModel(data.model);
      } else if (data.type === 'scale_change') {
        setScale(data.scale);
      }
    };
  
    ws.onclose = () => {
      console.log('WebSocket disconnected');
    };
  
    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    return () => {
      ws.close();
    };
  }, [roomNumber, userInfo.id]);
  
  useEffect(() => {
    if (socket && host && controlsRef.current) {
      const controls = controlsRef.current;
  
      // Add event listener to send camera updates only if the client is the host
      const handleControlsChange = () => {
        const cameraState = {
          position: controls.object.position.toArray(),
          rotation: controls.object.rotation.toArray(),
        };
        socket.send(
          JSON.stringify({
            type: 'camera_update',
            cameraState,
          })
        );
      };
  
      controls.addEventListener('change', handleControlsChange);
  
      // Cleanup listener on unmount or when host changes
      return () => {
        controls.removeEventListener('change', handleControlsChange);
      };
    }
  }, [socket, host]);
  
  
  const handleModelChange = (model) => {
    setCurrentModel(model);
    if (socket && host) {
      socket.send(JSON.stringify({ type: 'model_selected', model }));
    }
  };
  
  const handleScaleChange = (newScale) => {
    setScale(newScale);
    if (socket && host) {
      socket.send(JSON.stringify({ type: 'scale_change', scale: newScale }));
    }
  };
  

  return (
    <div className="anatomy-app">
      <header className="app-header">
        <center>
          <h1>Anatomy Explorer</h1>
        </center>
      </header>
      <p className="app-description">
        Welcome to the Anatomy Explorer! This interactive tool allows you to explore the human body's different systems in 3D. Use your mouse to interact with the model:
        <ul>
          <li><strong>Zoom:</strong> Scroll your mouse wheel up or down to zoom in and out.</li>
          <li><strong>Rotate:</strong> Click and drag with the left mouse button to rotate the model and view it from different angles.</li>
          <li><strong>Pan:</strong> Hold the right mouse button or Ctrl key while clicking and dragging to move the model around the screen.</li>
          <li><strong>Reset View:</strong> Use the controls in the corner or double-click the canvas to reset the view to its original position.</li>
        </ul>
        Click on the buttons above to load and explore different anatomical systems like the muscular, nervous, circulatory, and gastrointestinal systems. Adjust the model’s scale with the slider and hover over parts of the model to learn more about each structure.
      </p>

      <div className="app-controls">
        <button className="app-button" onClick={() => {
          setCurrentModel('muscles.glb');
          if (socket) {
            socket.send(JSON.stringify({ type: 'model_selected', model: 'muscles.glb' }));
          }
        }}>
          Muscular System
        </button>
        <button className="app-button" onClick={() => {
          setCurrentModel('nervous_system4.glb');
          if (socket) {
            socket.send(JSON.stringify({ type: 'model_selected', model: 'nervous_system4.glb' }));
          }
        }}>
          Nervous System
        </button>
        <button className="app-button" onClick={() => {
          setCurrentModel('Skeleton.glb');
          if (socket) {
            socket.send(JSON.stringify({ type: 'model_selected', model: 'Skeleton.glb' }));
          }
        }}>
          Skeletal System
        </button>
        <button className="app-button" onClick={() => {
          setCurrentModel('Circulatory_system.glb');
          if (socket) {
            socket.send(JSON.stringify({ type: 'model_selected', model: 'Circulatory_system.glb' }));
          }
        }}>
          Female Circulatory System
        </button>
        <button className="app-button" onClick={() => {
          setCurrentModel('Circulatory_system2.glb');
          if (socket) {
            socket.send(JSON.stringify({ type: 'model_selected', model: 'Circulatory_system2.glb' }));
          }
        }}>
          Male Circulatory System
        </button>
        <button className="app-button" onClick={() => {
          setCurrentModel('gastrointestinal_tract.glb');
          if (socket) {
            socket.send(JSON.stringify({ type: 'model_selected', model: 'gastrointestinal_tract.glb' }));
          }
        }}>
          Gastrointestinal System
        </button>
      </div>

      <div className="app-slider">
        <label htmlFor="scale-slider">Model Scale: {scale.toFixed(2)}</label>
        <input id="scale-slider" type="range" min="0.1" max="2" step="0.1" value={scale} onChange={(e) => {
          const newScale = parseFloat(e.target.value);
          setScale(newScale);
          if (socket) {
            socket.send(JSON.stringify({ type: 'scale_change', scale: newScale }));
          }
        }} />
      </div>

      <div className="app-canvas" ref={mountRef}></div>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading model, please wait...</p>
        </div>
      )}

      {hoveredPart && (
        <div className="tooltip">
          {hoveredPart}
        </div>
      )}
   
    </div>
  );
};

export default AnatomyThreeScene;
