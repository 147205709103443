import React from 'react';
import { useSelector } from 'react-redux';
import InviteLink from '../InviteGuests';
import LiveQuizPage from './LiveQuizPage';

const BiochemReactionGame = () => {
  // Fetch user details from Redux
  const userDetails = useSelector((state) => state.userDetails);
  const user = userDetails?.user;

  // Derive the room number from user id (adjust the multiplier as needed)
  const roomNumber = user ? user.id * 42 : null;

  // If no user is logged in, display an error
  if (!user) {
    return <p>Error: User must be logged in to host a quiz!</p>;
  }

  return (
    <div>
      {/* Instructional Container */}
      <div
        className="instructional-container"
        style={{
          padding: '20px',
          backgroundColor: '#f9f9f9',
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          maxWidth: '800px',
          margin: '20px auto',
        }}
      >
        <center>
          <h2 style={{ fontFamily: 'Arial, sans-serif', color: '#2c3e50' }}>
            Biochemical Reactions &amp; Mechanisms Challenge
          </h2>
          <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#34495e' }}>
            Put your biochemistry skills to the test! This interactive quiz covers 
            enzyme-catalyzed reactions, metabolic pathways, and biomolecular 
            transformations. Compete with others and prove your expertise!
          </p>
          <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#34495e' }}>
            How to play:
          </p>
          <ol style={{ textAlign: 'left', fontSize: '16px', lineHeight: '1.8', color: '#34495e' }}>
            <li>Join the challenge using a unique room code provided by the host.</li>
            <li>
              Each question presents a biochemical reaction, enzyme, or pathway—identify 
              the correct mechanism, intermediate, or product.
            </li>
            <li>
              Points are awarded based on accuracy and speed. The faster you answer 
              correctly, the more points you earn!
            </li>
            <li>At the end of the game, the player with the highest score wins.</li>
          </ol>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#e74c3c' }}>
            Good luck, and may the best biochemist win!
          </p>
        </center>
      </div>

      {/* InviteLink & LiveQuizPage Section */}
      <div>
        <br />
        {/* Adjust topics to an array or a number depending on your component’s needs */}
        <InviteLink roomNumber={roomNumber} topics={[25]} />
        <br />
        <LiveQuizPage topics={25} roomNumber={roomNumber} />
      </div>
    </div>
  );
};

export default BiochemReactionGame;
