import React from 'react';
import { useSelector } from 'react-redux';
import LiveQuizPage from './LiveQuizPage';
import InviteLink from '../InviteGuests';

const QuizCaseDrugDiscovery = () => {
  const userDetails = useSelector((state) => state.userDetails); // Fetch user details from Redux
  const user = userDetails?.user;

  // Derive the room number from user id
  const roomNumber = user ? user.id * 35 : null;

  if (!user) {
    return <p>Error: User must be logged in to host a quiz!</p>;
  }

  return (
    <div>
      <div
        className="instructional-container"
        style={{
          padding: '20px',
          backgroundColor: '#f9f9f9',
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          maxWidth: '800px',
          margin: '20px auto',
        }}
      >
        <center>
          <h2 style={{ fontFamily: 'Arial, sans-serif', color: '#2c3e50' }}>
            How to Play: Drug Discovery Quiz
          </h2>
          <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#34495e' }}>
            Welcome to the Drug Discovery Quiz! Test your knowledge of ADME, bioisosteres, 
            Caco-2 permeability, logP, and other essential concepts in the drug development process.
          </p>
          <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#34495e' }}>
            Here’s how it works:
          </p>
          <ol style={{ textAlign: 'left', fontSize: '16px', lineHeight: '1.8', color: '#34495e' }}>
            <li>
              The host starts the game focused on key drug discovery and development concepts.
            </li>
            <li>
              Participants join the game using a unique room code provided by the host.
            </li>
            <li>
              Each question will test knowledge on ADME, logP/logD, bioisosteric replacement, 
              and other drug discovery principles.
            </li>
            <li>
              Points are awarded based on accuracy and speed. The faster you answer correctly, 
              the more points you earn!
            </li>
            <li>
              At the end of the game, the participant with the highest score wins!
            </li>
          </ol>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#e74c3c' }}>
            Good luck, and may the best scientist win!
          </p>
        </center>
      </div>

      <div>
        <br />
        {/* Render InviteLink for participants to join, specifying topic 64 */}
        <InviteLink roomNumber={roomNumber} topics={[64]} />
        <br />
        {/* Render LiveQuizPage for drug discovery topic 64 */}
        <LiveQuizPage topics={64} roomNumber={roomNumber} />
      </div>
    </div>
  );
};

export default QuizCaseDrugDiscovery;
