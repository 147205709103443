import React from 'react';
import './InteractiveLabs.css'; // Importing the CSS file
import useSound from '../Sound';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Arcade() {
    const {play,stop,setLoop} = useSound('/ui-click-43196.mp3'); // Local file path
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate(); // Hook to programmatically navigate

    const handleSound = () => {
        play();
    };

    const handleAccess = (path) => {
        play();
        if (userInfo) {
            navigate(path); // Navigate if the user is logged in
        } else {
            alert("Please login to check out our study games");
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    return (
        <div className="outer-lab-container">
            <div className="lab-container">
                <h1 className="lab-title">Educational Games</h1>
                <div className="lab-sections">
           {/*     <div className="lab-section">
                        <h2>Choose your topic! (Premium)</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/FunQuizzes')}>Choose your topics to be quizzed on</a>
                            </li>
                            
                        </ul>
                    </div> */}
                    
                     <div className="lab-section">
                        <h2>Biochemistry Games</h2>
                        <ul className="lab-list">
                        <li className="lab-link">
                            <a onClick={() => handleAccess('/Biochem')}>
                                <img 
                                    src="image/pathways.jpg" 
                                    alt="Nomenclature Diagram" 
                                    style={{ width: '80%', height: '25%' }} 
                                />
                                How much do you know about Biochemical Mechanisms
                            </a>
                        </li>
                        </ul>
                    </div>
<div className="lab-section">
                        <h2>Organic Chemistry Games</h2>
                        <ul className="lab-list">
                        <li className="lab-link">
                            <a onClick={() => handleAccess('/nomenclature')}>
                                <img 
                                    src="image/nomenclature.png" 
                                    alt="Nomenclature Diagram" 
                                    style={{ width: '80%', height: '25%' }} 
                                />
                                Guess the IUPAC name!
                            </a>
                        </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/stereocentergame')}>
                                <img 
                                    src="image/R_S.png" 
                                    alt="Nomenclature Diagram" 
                                    style={{ width: '80%', height: '25%' }} 
                                />
                                Guess the stereocenter of the carbon!</a>
                            </li>
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Physics  Games</h2>
                        <ul className="lab-list">
                        <li className="lab-link">
                            <a onClick={() => handleAccess('/Kinematics')}>
                                <img 
                                    src="image/kinetics.jpg" 
                                    alt="Physics Kinetics" 
                                    style={{ width: '80%', height: '25%' }} 
                                />
                                Solve the Kinematics Problem!
                            </a>
                        </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/electrostatics')}>
                                <img 
                                    src="image/electrostatics.png" 
                                    alt="Nomenclature Diagram" 
                                    style={{ width: '80%', height: '25%' }}
                                /> <br/>
                                How much do you know about electrostatics?!</a>
                            </li>
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Pharmacology Games!</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/DrugDiscoveryGame')}>Do you remember the concepts of drug discovery?</a>
                            </li>
                            
                        </ul>
                    </div>
                    
                
                </div>
            </div>
        </div>
    );
}

export default Arcade;
