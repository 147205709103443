import React from 'react';
import { useParams } from 'react-router-dom';
import AnatomyThreeScene from './AnatomyThree';

const AnatomyInviteCase2 = () => {
  const { roomNumber: paramRoomNumber, userId: paramUserId } = useParams(); // Extract params

  // Log the params to debug
  console.log("Params from useParams:", { paramRoomNumber, paramUserId });

  // Validate the parameters
  const isValidRoomNumber = paramRoomNumber && /^\d+$/.test(paramRoomNumber); // Check if roomNumber is numeric
  const isValidUserId = paramUserId && /^\d+$/.test(paramUserId); // Check if userId is numeric

  // Assign validated parameters to variables
  const roomNumber = isValidRoomNumber ? parseInt(paramRoomNumber, 10) : null;
  const userId = isValidUserId ? parseInt(paramUserId, 10) : null;

  if (!roomNumber || !userId) {
    return (
      <p>
        Error: Invalid room number or user ID provided. Please ensure the URL is in the correct format.
      </p>
    );
  }

  // Render the AnatomyThreeScene directly with the validated and transformed variables
  return (
    <div>
      <AnatomyThreeScene roomNumber={roomNumber} userId={userId} />
    </div>
  );
};

export default AnatomyInviteCase2;
