import React, { useState } from 'react';
import AnatomyThreeScene from './AnatomyThree';

const AnatomyInviteCase = () => {
  const [roomNumber, setRoomNumber] = useState('');
  const [userId, setUserId] = useState('');
  const [startSession, setStartSession] = useState(false);

  const handleJoinRoom = (e) => {
    e.preventDefault();
    if (roomNumber.trim() && userId.trim()) {
      setStartSession(true);
    } else {
      alert('Please enter both Room Number and User ID.');
    }
  };

  return (
    <div>
      {!startSession ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h2>Join Anatomy Session</h2>
          <form onSubmit={handleJoinRoom}>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="roomNumber">Room Number:</label>
              <input
                id="roomNumber"
                type="text"
                value={roomNumber}
                onChange={(e) => setRoomNumber(e.target.value)}
                placeholder="Enter Room Number"
                style={{ marginLeft: '10px', padding: '5px' }}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="userId">User ID:</label>
              <input
                id="userId"
                type="text"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder="Enter Your User ID"
                style={{ marginLeft: '10px', padding: '5px' }}
              />
            </div>
            <button
              type="submit"
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                cursor: 'pointer',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
              }}
            >
              Join Room
            </button>
          </form>
        </div>
      ) : (
        // Pass the entered roomNumber and userId as props to AnatomyThreeScene
        <AnatomyThreeScene roomNumber={roomNumber} userId={userId} />
      )}
    </div>
  );
};

export default AnatomyInviteCase;
